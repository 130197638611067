import React, { useState, useEffect } from "react";
import { Modal, Button, Header, Image, Dropdown, Radio } from "semantic-ui-react";
import {  DownArrow } from "../../../../functional/global-image-import";
import { useDispatch } from 'react-redux';

const trigger = (
    <span className="text">
        Select grade for this book to teach <Image src={DownArrow} />
    </span>
)

const SelectGradeModal = (props) => {
    const [grade, setGradeList] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
        onSetGradeOptions()
    }, []);

    const onClickCheckBox = (id) => {
        setSelectedValue(id)
    }

    const onSetGradeOptions = () => {
        let gradeDetails = props?.bookGrade && JSON.parse(props?.bookGrade)
        setGradeList(gradeDetails === undefined ? [] : gradeDetails);
    }

    return (
        <Modal size="tiny" open={props.open} onClose={props.close} className="chooseGradeModal" dimmer="inverted">
            <Modal.Content>
                <Header as="h3">Choose Grade To Teach</Header>
                <div className='chooseGradeBody'>

                    <Dropdown simple item fluid trigger={trigger} icon={DownArrow}>

                           <Dropdown.Menu>
                            {grade?.map((singleGradeObj) => {

                                const gradeId = singleGradeObj.gradeId
                                return (
                                    <Dropdown.Item>
                                        <Radio
                                            key={gradeId}
                                            name='radioGroup' closeOnDocumentClick
                                            value={singleGradeObj.gradeName}
                                            checked={selectedValue === gradeId}
                                            onChange={() => onClickCheckBox(singleGradeObj.gradeId)}
                                            label={singleGradeObj.gradeName}
                                        />
                                    </Dropdown.Item>
                                )
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button disabled={selectedValue === null} onClick={() => props.onConfirm(selectedValue)}>Choose Grade</Button>
                </div>
            </Modal.Content>
        </Modal>
    )
}
export default SelectGradeModal;
